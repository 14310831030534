* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    height: 100%;
    overflow-x: hidden;
  }
  
  .header, .footer {
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vh;
  }

  .miniHeader {
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vh;
    height: 24px;
  }
  
  .body, .sidebar .block {
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
  }
  
  .header {
    height: 75px;
  }
  
  .main-content {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    gap: 10px;
  }
  
  .body {
    width: 70%;
    padding: 10px;
  }
  
  .sidebar {
    width: calc(30% - 10px);
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .sidebar .block {
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .sidebar .block-1 { height: 11.07vw; max-height: 155px; flex: 1;}
  .sidebar .block-2 { height: 4vw; max-height: 56; flex: 1;}
  .sidebar .block-3 { height: 11.07vw; max-height: 155px; }
  .sidebar .block-4 { height: 11.07vw; max-height: 155px; flex: 1;}
  .sidebar .block-5 { flex-grow: 1; min-height: 0; flex: 1;}
  
  @media (max-width: 1400px) {
    .main-content {
      flex-direction: column;
      align-items: stretch;
    }
  
    .body, .sidebar {
      width: auto;
      margin-bottom: 10px;
    }
  
    .sidebar .block-1,
    .sidebar .block-3,
    .sidebar .block-4 { min-height: calc(100%); flex: 1;}
    .sidebar .block-2 { min-height: 0; flex: 1;}
  }
  
  @media (max-width: 768px) {
    .header {
        justify-content: space-between; /* Выравнивание элементов в шапке */
    }

    .sidebar .block-1,
    .sidebar .block-3,
    .sidebar .block-4 { min-height: calc(100%); flex: 1;}
    .sidebar .block-2 { min-height: 0; flex: 1;}
}

@media only screen and (max-width: 375px) {
  .header {
    justify-content: space-between; /* Выравнивание элементов в шапке */
}

.sidebar .block-1,
.sidebar .block-3,
.sidebar .block-4 { min-height: calc(100%); flex: 1;}
.sidebar .block-2 { min-height: 0; flex: 1;}
}