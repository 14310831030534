body, html {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  height: 100%;
  overflow-x: hidden;
}

.article-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex: 1 1;
}

.article-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex: 1 1;
}

.article-container {
  width: 70%;
  padding: 8vh;
  background-color: rgba(236, 236, 236, 0.4);
  border-radius: 20px;
  margin: 0 0 0 10px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Изменено с center на flex-start */
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.article {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Изменено с center на flex-start */
}

.article-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  align-self: flex-start; /* Добавлено для выравнивания по левому краю */
  color: #333;
}

.article-date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 15px;
  align-self: flex-start; /* Добавлено для выравнивания по левому краю */
}

.article-image {
  max-width: 60%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  align-self: flex-start; /* Добавлено для выравнивания по левому краю */
}

.article-content {
  font-size: 1rem;
  width: 100%; /* Добавлено для обеспечения полной ширины контента */
  color: #333;
}

.article-content p {
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  
}

a:hover
{
  opacity: 0.71; /* Прозрачность текста */
  color: #4F2D6B; /* Цвет текста */
}

.article-content img,
.article-content video {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .article-container {
      width: 100%;
      margin: 0;
      padding: 5vh;
  }
  .article {
      padding: 5px;
  }
}