.page1-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box; /* Учитываем padding в ширину */
    flex: 1 1;
}

.page1-title {
    font-size: 2rem;
    color: rgba(79, 45, 107, 0.71); /* Прозрачный цвет заголовка */
    margin-bottom: 5vh; /* Отступ снизу */
    text-align: center; /* Центрируем текст по горизонтали */
}

.page1-container {
    width: calc(100%);
    height: calc(100% - 60px);
    padding: 8vh;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 0; /* Центрируем по горизонтали */
    max-width: 100%; /* Добавляем максимальную ширину для безопасности */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box; /* Учитываем padding в ширину */
}

.input-field {
    width: 80%; /* Ширина инпутов - 80% от ширины контейнера для мобильных */
    padding: 15px;
    margin-bottom: 2vh; /* Отступ между инпутами */
    border-radius: 10px; /* Закругленные углы инпутов */
    border: 1px solid rgba(79, 45, 107, 0.71); /* Граница инпутов */
}

.error-message {
    color: red; /* Цвет текста ошибок */
    font-size: 14px; /* Размер шрифта для сообщений об ошибках */
    margin-top: -10px; /* Минимальный отступ сверху (можно настроить) */
    margin-bottom: 10px; /* Отступ снизу для разделения с инпутами */
    
    width: calc(80%); /* Ширина сообщения об ошибке равна ширине инпута */
}

.cta-button {
    background-color: #de4a36;
    background-image: linear-gradient(to right, #de4a36, #f06a56);
    background-size: 200% 200%;
    background-position: 0% 50%;
    border: none;
    color: white;
    padding: 1.5vh 3vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2vw;
    border-radius: 10px;
 
    margin-top: 2vh; /* Отступ между элементами */
 }
 
 .cta-button:hover {
    background-position: 100% 50%;
    background-color: #f06a56;
    box-shadow: 0 0 20px rgba(240,106,86,0.5);
 }
 
 /* Остальные стили остаются без изменений */

@media (max-width:768px) {
   .page1-container .cta-button {
       background-color:#de4a36 ;
       background-image :linear-gradient(to right ,#de4a36 ,#f06a56); 
       background-size :200%200%; 
       background-position :0%50%; 
       border :none ; 
       color:white ; 
       padding :1.5vh3vw ; 
       text-align :center ; 
       text-decoration :none ; 
       display :inline-block ; 
       font-size :5vw ; 
       border-radius :10px;

       margin-top :2vh;/* Отступ между элементами */ 
   }

   .input-field {
       width :100%; 
       padding :15px; 
       margin-bottom :2vh; 
       border-radius :10px; 
       border :1px solid rgba(79 ,45 ,107 ,0.71); 
   }
}

.steps-container {
   display:flex ;
   justify-content:center ;/* Центрируем по горизонтали */ 
   color :rgba(79 ,45 ,107 ,0.71);/* Прозрачный цвет заголовка */ 
}

.step-circle {
   width :40px;/* Ширина кружка */ 
   height :40px;/* Высота кружка */ 
   border-radius :50%;/* Закругляем углы */ 
   background-color :#ffffff;/* Цвет фона по умолчанию */ 
   border :2px solid rgb(79 ,45 ,107);/* Красная рамка для неактивного круга */ 
   display :flex ; 
   align-items :center;/* Центрируем текст по вертикали */ 
   justify-content :center;/* Центрируем текст по горизонтали */ 
   margin :05px ;/* Отступы между кружками */ 
   font-weight:bold ;/* Жирный текст */ 
}

.step-circle.active {
   background-color :rgba(79 ,45 ,107 ,0.71);/* Цвет фона для активного шага */ 
   color:white ;/* Цвет текста для активного шага */ 
   border :2px solid rgb(79 ,45 ,107);/* Можно оставить ту же рамку , или изменить если нужно */ 
}