.admin-page-body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование по горизонтали */
    padding: 30px;
    overflow-y: auto; /* Добавляем скролл, если контент превышает высоту экрана */
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
}

.admin-page-title, .admin-page-sub-title {
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
}

.admin-page-title {
    font-size: 60px;
    text-align: center; /* Центрирование текста */
    margin-top: 10vh; /* Отступ от верхнего края только для main-title */
}

.admin-page-sub-title {
    font-size: 30px;
    text-align: center; /* Центрирование текста */
    margin-top: 2vh; /* Отступ между элементами */
}

.admin-page-cta-button {
    background-color: #de4a36;
    background-image: linear-gradient(to right, #de4a36, #f06a56);
    background-size: 200% 200%;
    background-position: 0% 50%;
    border: none;
    color: white; /* Цвет текста */
    padding: 15px 30px; /* Увеличиваем внутренние отступы для улучшения кликабельности */
    text-align: center;
    text-decoration: none; /* Убираем подчеркивание */
    display: inline-block;
    font-size: 1.2rem; /* Увеличиваем размер шрифта для лучшей читабельности */
    border-radius: 10px;
    cursor: pointer;
    transition: background-position 0.5s ease, background-color 0.5s ease, box-shadow 0.5s ease;

    margin-top: 5vh; /* Отступ между элементами */
}

.admin-page-cta-button:hover {
    background-position: 100% 50%;
}

/* Адаптивные стили для экранов с шириной до 768px */
@media (max-width: 768px) {
   .admin-page-title {
       font-size: 30px; 
       margin-top: 5vh;
   }
   
   .admin-page-sub-title {
       font-size :18px ; 
   }
   
   .admin-page-cta-button {
       font-size :1rem ; 
       padding :10px ; 
   }
}

/* Адаптивные стили для экранов с шириной до 480px */
@media (max-width: 480px) {
   .admin-page-title {
       font-size :24px ;
   }
   
   .admin-page-sub-title {
       font-size :16px ;
   }
}

/* Адаптивные стили для экранов с шириной до 320px */
@media (max-width: 320px) {
   .admin-page-title {
       font-size :20px ;
   }
   
   .admin-page-sub-title {
       font-size :12px ;
   }
}
