/* Пример стилей для заголовка */
.block-4 h2 {
    font-size: 2vh; /* Размер шрифта заголовка */
    color: rgba(79, 45, 107, 0.71); /* Цвет текста заголовка */
    margin: 0 0 10px 0; /* Отступ снизу для разделения заголовка и других элементов */
    padding: 1vh;
}

/* Стили для кнопки обратной связи */
.feedback-button {
    border: 2px solid #4F2D6B; /* Рамка */
    opacity: 0.71; /* Прозрачность текста */
    background-color: transparent; /* Без заливки */
    color: #333; /* Цвет текста */
    padding: 1.5vh 3vw; /* Отступы внутри кнопки */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem; /* Размер шрифта кнопки */
    border-radius: 10px; /* Закругление углов кнопки */
    cursor: pointer;
    transition: border-color .5s ease, box-shadow .5s ease;
    width: auto; /* Ширина по содержимому */
    margin-top: 2vh; /* Отступ сверху перед кнопкой */
}

/* Стили для попапа обратной связи */
.feedback-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: 20px;
    z-index: 1000;
    
    /* Добавляем следующие свойства для центрирования содержимого */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Стили для сообщения */
.feedback-message {
   margin-top :10px ;
   color :green ;/* Цвет сообщения */ 
   text-align: center; /* Центрируем текст сообщения */
}

/* Стили для кнопки в попапе */
.feedback-popup button {
    border: 2px solid #4F2D6B; /* Красная рамка */
    opacity: 0.71; /* Прозрачность текста */
    background-color: transparent; /* Без заливки */
    color: #333; /* Общий цвет текста */
    padding: 1.5vh 3vw; /* Отступы внутри кнопки */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem; /* Размер шрифта кнопки в попапе */
    border-radius: 10px; /* Закругление углов кнопки */
    cursor: pointer;
    transition: border-color .5s ease, box-shadow .5s ease;
    width: auto; /* Ширина по содержимому */
    margin: 2vh;
}

/* Закрывающий элемент (иконка) попапа */
.close-icon {
    position: absolute; /* Позиционирование относительно попапа */
    top: 5px; /* Отступ сверху от верхней грани попапа */
    right: 10px; /* Отступ справа */
    font-size: 1.5rem; /* Размер шрифта для крестика */
    cursor: pointer; /* Курсор при наведении */
}

/* Заголовок в попапе */
.feedback-popup h2 {
   margin-top: 30px; /* Отступ сверху для заголовка, чтобы он не перекрывался с крестиком */ 
}

/* Текстовое поле в попапе */
.feedback-popup textarea {
   width: 100%;
   height: 100px; /* Высота текстового поля */
   margin-bottom: 2vh; /* Отступ между элементами */ 
}

/* Оценка в виде кругов в попапе */
.rating {
   display: flex;
}

.circle {
   width: 30px;
   height: 30px;
   border-radius: 50%;
   border: 1px solid #4F2D6B;
   opacity: 0.71; /* Прозрачность текста */ 
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 5px;
   cursor:pointer;
}

.circle.selected {
   background-color:#4F2D6B;/* Цвет выделенного круга */ 
   opacity:.71;/* Прозрачность текста */ 
   color:white;/* Цвет текста выделенного круга */ 
}

.feedback-message {
   margin-top :10px ;
   color :green ;/* Цвет сообщения */ 
}

/* Адаптивные стили для экранов с шириной до 768px */
@media (max-width: 768px) {

    .block-4 h2 {
        font-size: 3vh;
        color: rgba(79, 45, 107, 0.71);
        margin: 0 0 10px 0;
        padding: 1vh;
    }
    
   .feedback-button {
       width:auto ; 
       height:auto ; 
       font-size :1rem ; 
       padding :10px ; 
       min-width :140px ; 
       max-width :calc(100% -20px); 
   }

   .feedback-popup button {
       width:auto ; 
       height:auto ; 
       font-size :1rem ; 
       padding :10px ; 
       min-width :140px ; 
       max-width :calc(100% -20px); 
       margin: 2vh;
   }
}