.header {
    height: 75px;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vh;
    position: relative; /* Для позиционирования бургер-меню */
}

.logo-container {
    margin-left: 2vh; /* Отступ от левого края */
}

.logo {
    width: auto; /* Ширина логотипа */
    height: auto; /* Пропорциональное изменение высоты */
}

.menu {
    display: flex; /* Используем flexbox для выравнивания */
    align-items: center;
    justify-content: center; /* Центрируем меню */
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
    flex-grow: 1; /* Позволяем меню занимать доступное пространство */
}

.header .menu-link, .login-link {
    text-decoration: none; /* Убираем подчеркивание */
    color: inherit; /* Наследуем цвет от родительского элемента */
    margin: 0 20px;
}

/* Эффект при наведении */
.menu-link:hover, .login-link:hover  {
    color: #6A4F8D; /* Изменяем цвет при наведении */
}

.login-link {
    font-size: 20px;
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
}

.burger {
    display: none; /* Скрываем бургер-меню по умолчанию */
    font-size: 24px;
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
}

/* Стиль для бургер-меню при открытом состоянии */
.menu.open {
    display: flex; /* Показываем меню при открытии */
}

/* Адаптивные стили */
@media (max-width: 815px) {
    .menu, .login-link {
        display: none; /* Скрываем меню на маленьких экранах по умолчанию */
        position: absolute; /* Позиционируем меню относительно родителя */
        top: 75px; /* Начинаем под шапкой (высота заголовка) */
        right: 0; /* Прикрепляем к правой части экрана */
        height: 100vh; /* Высота меню на весь экран минус высота заголовка */
        background-color: rgb(236, 236, 236); /* Фон с большей прозрачностью для лучшей видимости */
        opacity: 1; /* Прозрачность текста */
        flex-direction: column; /* Выстраиваем элементы в столбик */
        padding-top: 20px; /* Отступ сверху для первого элемента меню */
        z-index: 1000; /* Чтобы меню было поверх других элементов */
        transition: transform 0.3s ease; /* Плавная анимация открытия/закрытия */
    }

    .menu.open {
        display: flex; /* Показываем меню при открытии */
        height: calc(100vh - 75px); /* Убедитесь, что высота остается корректной при открытии */
        border-radius: 20px;
    }

    .burger {
        display: block; /* Показываем бургер-меню на маленьких экранах */
        cursor: pointer; /* Указатель при наведении на бургер-меню */
    }
}