nav {
  margin-bottom: 20px;
  text-decoration: underline
}

nav a {
  text-decoration: none;
  opacity: 0.71; /* Прозрачность текста */
  color: #4F2D6B;
}

nav a:hover {
  text-decoration: underline; /* Подчеркивание при наведении */
  color: #6A4F8D; /* Изменяем цвет при наведении */
}