/* DepositRateBlock.css */
.blockWrapper {
  color: rgba(79, 45, 107, 0.71);
  font-family: Arial, sans-serif;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  height: auto; /* Устанавливаем высоту на auto для автоматической подстройки */
  box-sizing: border-box;
  justify-content: space-between;
  overflow: hidden; /* Скрываем переполнение */
}

.title {
  margin: 0 0 1vh;
  font-size: 2.2vh;
  line-height: 1.2;
}

.rateContainer {
  display: flex;
  align-items: center;
  margin: 0.5vh 0;
}

.rateLabel {
  font-size: 1.8vh;
  margin-right: 0.5vh;
}

.rateValue {
  font-size: 2vh;
  margin-left: 0.5vh;
}

.ratePositive {
  color: green;
}

.rateNegative {
  color: red;
}

.bankLinkContainer {
  display: flex;
  align-items: center;
  margin-top: 1vh;
}

.bankLabel {
  font-size: 1.8vh;
  margin-right: 0.5vh;
}

.link {
  color: inherit;
  text-decoration: none;
  font-size: 1.8vh;
}

.link:hover {
  text-decoration: underline;
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
  .blockWrapper {
      padding: 0.8vh;
      overflow-y: auto; /* Добавляем прокрутку по вертикали, если контент превышает высоту */
      height: auto; /* Высота контейнера будет автоматически подстраиваться */
  }
  
  .title {
      font-size: 3vh;
  }
  
  .rateLabel, .bankLabel {
      font-size: 2vh;
  }
  
  .rateValue, .link {
      font-size: 2vh;
  }
}