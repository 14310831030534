.currency-rates {
  display: flex;
  justify-content: space-between; /* Распределяем контент по всей ширине */
  align-items: center; /* Центрируем контент по вертикали */
  padding: 10px;
  font-size: 2vh;
  opacity: 0.71; /* Прозрачность текста */
  color: #4F2D6B; /* Цвет текста */
  white-space: nowrap; /* Запрет на перенос строк */
  overflow: hidden; /* Скрываем переполнение */
  text-overflow: ellipsis; /* Добавляем многоточие для переполнения */
}

.rate {
  display: flex;
  align-items: center;
}

.rate:not(:last-child) {
  margin-right: 5px; /* Отступы вокруг валют */
}

.rate:not(:last-child)::after {
  content: ' ● '; /* Круглый разделитель */
  margin-left: 5px; /* Отступ слева от разделителя */
}

/* Уменьшение размера шрифта при переполнении */
@media (max-width: 768px) {
  .currency-rates {
      font-size: 2vh;
  }

  .currency-logo {
      width: 2.5vh;
      height: 2.5vh;
  }
}

/* Уменьшение размера шрифта для более узких экранов */
@media (max-width: 480px) {
  .currency-rates {
      font-size: 2vh; /* Уменьшаем размер шрифта для маленьких экранов */
  }
}