/* News.css */

.news-block {
    height: auto; /* Занимает всю высоту родительского блока */
    max-height: 155px; /* Максимальная высота блока */
    overflow: hidden; /* Скрыть переполнение */
    display: flex;
    flex-direction: column; /* Вертикальное расположение элементов */
    padding: 1vh;
  }
  
  .news-title {
    font-size: 2vh; /* Размер шрифта заголовка */
    color: #4F2D6B; /* Цвет текста заголовка */
    opacity: 0.71; /* Прозрачность текста */
    margin: 0 0 10px 0; /* Отступ снизу для разделения заголовка и списка новостей */
  }
  
  .news-list {
    flex-grow: 1; /* Занимает оставшееся пространство */
    overflow-y: auto; /* Добавляем вертикальную прокрутку */
    display: flex; /* Устанавливаем флекс-контейнер */
    flex-direction: column; /* Устанавливаем направление флекса на столбик */

  }
  
  .news-item {
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
    padding: 5px 0; /* Отступы между новостями */
    text-decoration: none; /* Убираем подчеркивание у ссылок */
  }
  
  .news-item:hover {
    text-decoration: underline; /* Подчеркивание при наведении для кликабельных заголовков */
  }
  
  @media (max-width: 768px) {
    .news-title {
        font-size: 3vh;
        color: #4F2D6B;
        opacity: 0.71;
        margin: 0 0 10px 0;
    }

    .news-item {
        opacity: 0.71;
        color: #4F2D6B;
        padding: 5px 0;
        text-decoration: none;
        font-size: 2vh;
    }
  
  }