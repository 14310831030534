body, html {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    height: 100%;
    overflow-x: hidden;
  }
  
  .pay-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex: 1 1;
  }
  
  .pay-container {
    width: 70%;
    padding: 8vh;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 0 0 0 10px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* Изменено с center на flex-start */
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .pay {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Изменено с center на flex-start */
  }
  
  .pay-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    align-self: flex-start; /* Добавлено для выравнивания по левому краю */
    color: #333;
  }
  
  .pay-date {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 15px;
    align-self: flex-start; /* Добавлено для выравнивания по левому краю */
  }
  
  .pay-image {
    max-width: 60%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
    align-self: flex-start; /* Добавлено для выравнивания по левому краю */
  }
  
  .pay-content {
    font-size: 1rem;
    width: 100%; /* Добавлено для обеспечения полной ширины контента */
    color: #333;
  }
  
  .pay-content p {
    margin-bottom: 10px;
  }
  
  .pay-content img,
  .pay-content video {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .pay-container {
        width: 100%;
        margin: 0;
        padding: 5vh;
    }
    .pay {
        padding: 5px;
    }
  }