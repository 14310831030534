body, html {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    height: 100%;
    overflow-x: hidden;
}

.blog-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex: 1 1;
}

.blog-container {
    width: 70%; /* Установите ширину контейнера в 70% */
    padding: 8vh;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 0 0 0 10px; /* Установите отступ слева */
    max-width: 100%; /* Добавляем максимальную ширину для безопасности */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* Изменено с center на flex-start для выравнивания по левому краю */
    box-sizing: border-box;
    overflow-y: auto; /* Добавьте бесконечную прокрутку */
    overflow-x: hidden; /* Уберите горизонтальный скролл */
}

.blog {
    width: 100%; /* Ширина контента внутри контейнера */
    padding: 10px;
    background-color: transparent; /* Уберите фон, если он не нужен */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Изменено с center на flex-start для выравнивания по левому краю */
}

.blog .article-content {
    font-size: 1rem;
    margin-bottom: 5vh;
}

/* Стили для статьи */
.blog .article {
    margin-bottom: 20px;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    padding: 10px;
    width: 100%; /* Ширина статьи внутри контейнера */
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
    .blog-container {
        width: 100%; /* Установите ширину контейнера на 100% для мобильных устройств */
        margin: 0; /* Уберите отступ слева для мобильных устройств */
        padding: 5vh; /* Уменьшите padding для мобильных устройств */
    }
    .blog {
        padding: 5px; /* Уменьшите padding для мобильных устройств */
        align-items: flex-start; /* Убедитесь, что выравнивание остается по левому краю на мобильных устройствах */
    }
    .blog .article {
        padding: 5px; /* Уменьшите padding для статей на мобильных устройствах */
        align-items: flex-start; /* Убедитесь, что выравнивание остается по левому краю на мобильных устройствах */
    }
}