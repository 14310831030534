.body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование по горизонтали */
    padding: 30px;
    overflow-y: auto; /* Добавляем скролл, если контент превышает высоту экрана */
}

.main-title, .sub-title {
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
}

.main-title {
    font-size: 60px;
    text-align: center; /* Центрирование текста */
    margin-top: 10vh; /* Отступ от верхнего края только для main-title */
}

.sub-title {
    font-size: 30px;
    text-align: center; /* Центрирование текста */
    margin-top: 2vh; /* Отступ между элементами */
}

.image {
    width: 202px;
    height: 129px;
    object-fit: cover;
    margin-top: 2vh; /* Отступ между элементами */
}

.cta-button {
    background-color: #de4a36;
    background-image: linear-gradient(to right, #de4a36, #f06a56);
    background-size: 200% 200%;
    background-position: 0% 50%;
    border: none;
    color: white; /* Цвет текста */
    padding: 15px 30px; /* Увеличиваем внутренние отступы для улучшения кликабельности */
    text-align: center;
    text-decoration: none; /* Убираем подчеркивание */
    display: inline-block;
    font-size: 1.2rem; /* Увеличиваем размер шрифта для лучшей читабельности */
    border-radius: 10px;
    cursor: pointer;
    transition: background-position 0.5s ease, background-color 0.5s ease, box-shadow 0.5s ease;
    
    margin-top: 5vh; /* Отступ между элементами */
}

.cta-button:hover {
    background-position: 100% 50%;
    background-color: #f06a56;
    box-shadow: 0 0 20px rgba(240, 106, 86, 0.5);
    color: white; /* Сохраняем белый цвет текста при наведении */
    text-decoration: none; /* Убираем подчеркивание при наведении */
}

/* Адаптивные стили для экранов с шириной до 768px */
@media (max-width: 768px) {
   .main-title {
       font-size: 30px; 
       margin-top: 5vh;
   }
   
   .sub-title {
       font-size :18px ; 
   }
   
   .image {
       width :160px ;
       height:auto ; 
   }
   
   .body .cta-button {
    width:auto ; 
    height:auto ; 
    font-size :1rem ; 
    padding :10px ; 
    margin :5vh ; 
    min-width :140px ; 
    max-width :calc(100% -20px); 
}
}

/* Адаптивные стили для экранов с шириной до 480px */
@media (max-width: 480px) {
   .main-title {
       font-size :24px ;
       margin-top: 5vh;
   }
   
   .sub-title {
       font-size :16px ;
   }
   
   .image {
       width :120px ;
       height:auto ; 
   }
   
   .body .cta-button {
       width:auto ; 
       height:auto ; 
       font-size :1rem ; 
       padding :10px ; 
       margin :5vh ; 
       min-width :140px ; 
       max-width :calc(100% -20px); 
   }
}

/* Адаптивные стили для экранов с шириной до 320px */
@media (max-width: 320px) {
   .main-title {
       font-size :20px ;
       margin-top: 5vh;
   }
   
   .sub-title {
       font-size :12px ;
   }
   
   .image {
       width :100px ;
       height:auto ; 
   }
   
   .body .cta-button {
    width:auto ; 
    height:auto ; 
    font-size :1rem ; 
    padding :10px ; 
    margin :5vh ; 
    min-width :140px ; 
    max-width :calc(100% -20px); 
}
}