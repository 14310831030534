body, html {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    height: 100%;
}

.glossary-content {
    display: flex;
    width: 100%;
    color: #333;
}

.glossary-container {
    width: 70%; /* Ширина контейнера на десктопе */
    max-width: 800px; /* Максимальная ширина для более крупных экранов */
    padding: 8vh; /* Отступы */
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin-left: 0; /* Убираем отступ слева */
    margin-right: auto; /* Центрируем контейнер по горизонтали */
}

.glossary-item {
    margin-bottom: 10px; /* Отступ между вопросами */
}

.glossary-question {
    background-color: #fff; /* Белый фон для вопросов */
    border: none;
    border-radius: 10px;
    padding: 15px;
    width: 100%; /* Ширина вопроса */
    text-align: left;
    font-size: 1.2rem; /* Размер шрифта для вопросов */
    cursor: pointer; /* Указатель при наведении */
    position: relative; /* Для позиционирования стрелки */
    padding-right: 50px; /* Добавляем отступ справа для охранной зоны стрелки */
    overflow-wrap: break-word; /* Позволяет переносить длинные слова */
    white-space: normal; /* Позволяет тексту переноситься на новую строку */
}

.glossary-question:hover {
    background-color: #f0f0f0; /* Изменение фона при наведении */
}

.arrow {
    position: absolute;
    right: 15px; /* Отступ от правого края */
    width: 24px; /* Ширина стрелки */
    height: 24px; /* Высота стрелки */
    transition: transform 0.3s ease; /* Плавный переход для стрелки */
}

.arrow.open {
   transform: rotate(180deg); /* Поворот стрелки при открытом состоянии */
}

.glossary-answer {
    font-size: 1rem; 
    padding-left: 20px; /* Отступ для визуального отделения ответа от вопроса */
}

.glossary-toc {
    border-radius: 20px; /* Закругленные углы */
    padding: 15px; /* Отступы внутри оглавления */
    margin-bottom: 20px; /* Отступ снизу */
    text-decoration: none;
}

.glossary-toc h2 {
    font-size: 1.5rem; /* Размер заголовка оглавления */
    margin-bottom: 10px; /* Отступ снизу */
    color: #333; /* Цвет заголовка */
    
}

.glossary-toc ul {
    list-style-type: disc; /* Убираем маркеры списка */
    padding: 5px; /* Убираем отступы */
    
}

.glossary-toc li {
    cursor: pointer; /* Указатель при наведении */
    color: #333; /* Цвет текста для ссылок */
    margin-bottom: 5px; /* Отступ между пунктами списка */
    transition: color 0.3s ease; /* Плавный переход цвета при наведении */
}

.glossary-toc li:hover {
    color: #4F2D6B; /* Цвет текста при наведении */
    text-decoration: none;
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
    .glossary-container {
        width: 90%; 
        margin-left: 0; 
        padding: 5vh; 
        border-radius: 15px; /* Уменьшаем радиус для мобильных устройств */
    }

    .glossary-question {
        padding-right: 40px; /* Уменьшаем отступ справа для мобильных устройств */
        font-size: 1rem; /* Уменьшаем размер шрифта на мобильных устройствах */
        line-height: 1.4; /* Увеличиваем межстрочный интервал для удобства чтения */
    }

    .glossary-answer {
        font-size: 0.9rem; /* Уменьшаем размер шрифта для ответов на мобильных устройствах */
        padding-left: 15px; /* Уменьшаем отступы на мобильных устройствах */
        line-height: 1.4; /* Увеличиваем межстрочный интервал для удобства чтения */
    }

    .arrow {
        right: 10px; /* Уменьшаем отступ от правого края на мобильных устройствах */
        width: 20px; /* Уменьшаем размер стрелки на мобильных устройствах */
        height: 20px; /* Уменьшаем размер стрелки на мобильных устройствах */
    }
}
