/* Общие стили для футера */
.footer {
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    padding: 2vh 2vh; /* Отступы сверху и снизу 2vh */
    opacity: 0.71;
    color: #4F2D6B;
    align-items: stretch; /* Выравнивание по высоте */
    min-height: 100px; /* Минимальная высота */
    flex-wrap: nowrap; /* Не переносим блоки на новую строку */
  }
  
  /* Блоки футера */
  .footer-block {
    flex-basis: 33.33%; /* Каждый блок занимает 1/3 ширины */
    margin: 10px; /* Отступы между блоками */
    box-sizing: border-box; /* Учитываем padding и border в размерах */
    display: flex; /* Используем flex для внутреннего содержимого */
    flex-direction: column; /* Располагаем содержимое по вертикали */
    flex-grow: 1; /* Занимаем оставшееся пространство */
  }
  
  /* Заголовки блоков */
  .footer-block > h3 {
    margin-top: 0; /* Убираем верхний отступ */
    margin-bottom: 10px; /* Добавляем нижний отступ для равного расстояния */
  }
  
  /* Контент блоков */
  .footer-block > form {
    flex-grow: 1; /* Занимаем оставшееся пространство */
  }
  
  /* Левый блок: контент слева */
  .footer-menu {
    align-items: flex-start; /* Выравнивание по левому краю */
  }
  
  /* Средний блок: контент по центру */
  .footer-subscribe {
    align-items: center; /* Выравнивание по центру */
  }
  
  .footer-subscribe form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1; /* Занимаем оставшееся пространство */
  }
  
  .footer-subscribe input {
    width: 100%; /* Занимаем всю ширину */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
  }
  
  /* Стили для кнопки подписаться */
  .footer-subscribe form .cta-button-footer {
    border: 2px solid #4F2D6B; /* Красная рамка */
    background-color: transparent; /* Без заливки */
    color: #333; /* Общий цвет текста */
    padding: 1.5vh 3vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2vw;
    border-radius: 10px;
    cursor: pointer;
    transition: border-color 0.5s ease, box-shadow 0.5s ease;
    width: 100%; /* Адаптивная ширина */
  }
  
  .footer-subscribe form .cta-button-footer:hover {
    border-color: #4F2D6B; /* Изменяем цвет рамки при наведении */
    box-shadow: 0 0 20px rgba(86, 109, 240, 0.5);
  }
  
  /* Правый блок: контент справа */
  .footer-legal {
    align-items: flex-end; /* Выравнивание по правому краю */
  }
  
  /* Адаптивные стили для меньших экранов */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
    .footer-block {
      flex-basis: 100%; /* Каждый блок занимает всю ширину при адаптивном расположении */
      margin: 10px 0; /* Отступы сверху и снизу при адаптивном расположении */
      align-items: center; /* Центрируем содержимое по горизонтали */
    }

    .footer-subscribe form .cta-button-footer {
        border: 2px solid #4F2D6B; /* Красная рамка */
        background-color: transparent; /* Без заливки */
        color: #333; /* Общий цвет текста */
        padding: 1.5vh 3vw;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 5vw;
        border-radius: 10px;
        cursor: pointer;
        transition: border-color 0.5s ease, box-shadow 0.5s ease;
        width: 100%; /* Адаптивная ширина */
      }
    
  }
  
  .footer-menu a {
    text-decoration: none;
    color: #333;
  }
  
  .menu-link {
    /* Уберите любые дополнительные стили, которые могут быть применены к Link */
   
    margin: 0;
    margin-bottom: 10px;
  }
  
  /* Стили для правовой информации */
  
  .footer-legal a {
    text-decoration: none;
    color: #333;
  }
  
  /* Контейнер для заголовков и контента */
  .footer-block-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравнивание по левому краю */
  }
  
  .footer-block-header-container > h3 {
    margin-top: 0; /* Убираем верхний отступ */
    margin-bottom: 10px; /* Добавляем нижний отступ для равного расстояния */
  }
  
  .footer-block-content-container {
    flex-grow: 1; /* Занимаем оставшееся пространство */
  }