/* src/components/page5.css */

.page5-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование содержимого по горизонтали */
    height: auto; /* Высота адаптивная */
    width: 100%; /* Занимает всю ширину экрана */
    box-sizing: border-box;
}

.page5-title {
    font-size: 2rem;
    color: rgba(79, 45, 107, 0.71); /* Прозрачный цвет заголовка */
    margin-bottom: 5vh; /* Отступ снизу */
    text-align: center; /* Центрируем текст по горизонтали */
}

.financial-tools {
    display: flex;
    justify-content: space-between; /* Распределение блоков по ширине */
    width: calc(100%); /* Учитываем отступы */
    margin: 0; /* Центрируем по горизонтали */
    padding: 0; /* Отступы по краям */
    box-sizing: border-box; /* Учитываем padding в ширину */
    gap:2vh;
}

.tool-box {
    flex: 1; /* Занимает равную долю пространства */
    min-height: 100px; /* Минимальная высота */
    background-color: rgba(236, 236, 236, 0.4); /* Полупрозрачный фон блока */
    border-radius: 10px; /* Закругленные углы */
    border: 2px solid rgba(79, 45, 107, 0.71);
    padding: 2vh; /* Отступы внутри блока */
}

.tool-box h3 {
    color: rgb(79, 45, 107); /* Цвет заголовка */
    margin-bottom: 2vh; /* Отступ снизу от заголовка */
}

.tool-box p {
    color: rgba(79, 45, 107, 0.71); /* Цвет текста для параграфов */
    margin-top: 0; /* Убираем верхний отступ у параграфов */
    margin-bottom: 2vh; /* Отступ снизу для параграфов */
}

.tool-box ul {
    color: rgba(79, 45, 107, 0.71); /* Цвет текста для списков */
    margin-top: 0; /* Убираем верхний отступ у списков */
    margin-bottom: 2vh; /* Отступ снизу для списков */
}

.tools-title {
    font-size: 1.5rem;
    color: rgba(79, 45, 107, 0.71); /* Цвет заголовка */
    margin-top: 20px; /* Отступ сверху */
    margin-left: 0; /* Убираем отступ слева */
    align-self: flex-start; /* Выравнивание заголовка по левому краю */
    display: flex; /* Используем flexbox для выравнивания */
    align-items: center; /* Центрируем по вертикали */
    position: relative; /* Устанавливаем позицию относительно родителя */
    text-align: center; /* Центрируем текст по горизонтали */
}

.additional-tool-box p {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }
  
  .total-savings {
    word-break: break-word;
    hyphens: auto;
  }

.question-icon {
    cursor: pointer;
    margin-left: 5px; /* Отступ слева от вопроса */
    font-size: 0.8rem; /* Размер шрифта для вопросительного знака */
}

.tooltip {
    font-size: 1rem;
    position: absolute;
    background-color: rgb(236, 236, 236); 
    border: 2px solid rgba(79, 45, 107, 0.71);
    border-radius: 5px;
    padding: 10px;
    top: calc(-100% - 5px); /* Позиционируем тултип выше вопросительного знака */
    left: 35%; /* Центрируем тултип по горизонтали относительно вопроса */
    transform: translateX(110%); /* Сдвигаем тултип вправо на половину его ширины для позиционирования справа */
    z-index: 1000; /* Увеличиваем z-index, чтобы тултип был поверх других элементов */
}

.additional-tools {
    display: flex;
    justify-content: space-between; /* Распределение блоков по ширине */
    width: calc(100%); /* Учитываем отступы */
    margin: 0; /* Центрируем по горизонтали */
    padding: 0; /* Отступы по краям */
    box-sizing: border-box; /* Учитываем padding в ширину */
    gap: 2vh;
}

.additional-tool-box {
    flex-basis: calc(33.33%); /* Занимает треть ширины с учетом отступов */
    min-height: 100px; /* Минимальная высота */
    margin: 2vh 0; /* Отступы между блоками */

   background-color: rgba(236, 236, 236, 0.4); /* Полупрозрачный фон блока */
   border-radius: 10px; /* Закругленные углы */
   border: 2px solid rgba(79, 45, 107, 0.71);
   padding: 2vh; /* Отступы внутри блока */

   h3 {
       color: rgb(79, 45, 107); /* Цвет заголовка для дополнительных блоков */
       margin-bottom: 2vh; /* Отступ снизу от заголовка в дополнительных блоках */
   }

   p {
       color: rgba(79, 45, 107, 0.71); /* Цвет текста для параграфов в дополнительных блоках */
       margin-top: 0;
       margin-bottom: 2vh; /* Отступ снизу для параграфов в дополнительных блоках*/
   }

   ul {
       color: rgba(79,45,107,.71) ;/* Цвет текста для списков в дополнительных блоках */ 
       margin-top :0 ;
       margin-bottom :2vh ;/* Отступ снизу для списков в дополнительных блоках*/
   }
}

.final-block {
    width: calc(100% - 4vh); /* Учитываем отступы */
    min-height: 100px; /* Минимальная высота финального блока */
    
   background-color :rgba(236 ,236 ,236 ,0.4);
   border-radius :10px ;
   border :2px solid rgba(79 ,45 ,107 ,0.71);
   padding :2vh;

   h3 {
       color : rgb(79 ,45 ,107);/* Цвет заголовка финального блока */ 
       margin-bottom :2vh ;/* Отступ снизу от заголовка финального блока */ 
   }

   p {
       color : rgba(79 ,45 ,107 ,0.71) ;/* Цвет текста для параграфа в финальном блоке */ 
       margin-top :0 ;
       margin-bottom :2vh ;/* Отступ снизу для параграфа в финальном блоке*/
   }
}

.step-circle-final.active {
    background-color: rgba(79, 45, 107, 0.71);
    color: white;
    border: 2px solid rgb(79, 45, 107);
}

.step-circle-final {
    cursor: pointer; /* Устанавливаем курсор как указатель */
    display: flex; /* Используем flexbox для центрирования содержимого */
    justify-content: center; /* Центрируем содержимое по горизонтали */
    align-items: center; /* Центрируем содержимое по вертикали */
    width: 40px; /* Ширина круга */
    height: 40px; /* Высота круга */
    border-radius: 50%; /* Закругляем углы для создания круга */
    border: 2px solid rgb(79, 45, 107); /* Рамка круга */
    transition: background-color 0.3s; /* Плавный переход цвета фона */
    margin: 0 5px;
    font-weight: bold;
}

.step-circle-final:hover {
    background-color: rgba(79, 45, 107, 0.71);
    color: white;
    border: 2px solid rgb(79, 45, 107); /* Меняем цвет фона при наведении */
}

.flexible-text {
    display: inline; /* Оставляем текст в одной строке */
    white-space: normal; /* Позволяем тексту переноситься при нехватке места */
    word-wrap: break-word; /* Если текст слишком длинный, разрешаем переносить слова */
  }
  
  .flexible-text span {
    white-space: nowrap; /* Предотвращаем перенос суммы на новую строку, если для нее есть место */
  }

@media (max-width: 768px) {
    .financial-tools, .additional-tools, .final-block {
        justify-content: space-between;
        padding: 0;
        display: block;
    }

    .tool-box, .final-block {
        flex: 1 1;
        min-height: 100px;
        margin: 2vh 0;
        background-color: rgba(236, 236, 236, 0.4);
        border-radius: 10px;
        border: 2px solid rgba(79, 45, 107, 0.71);
        padding: 2vh;
    }
}