.miniHeader {
    font-size: 15px;
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
    background-color: rgba(236, 236, 236, 0.4);
    overflow: hidden; /* Скрываем содержимое за пределами контейнера */
    white-space: nowrap; /* Не переносим текст на новую строку */
    position: relative; /* Добавляем позиционирование для контейнера */
  }
  
  .marquee {
    display: inline-block;
    position: absolute; /* Добавляем абсолютное позиционирование для текста */
    left: 0; /* Начальная позиция текста за правым краем */
    transition: none; /* Отключаем переходы для плавной анимации */
  }
  
  .marquee-item:not(:last-child)::after {
    content: '●'; /* Круглый разделитель */
    margin: 0 2vh;
  }
  
  /* Адаптивность для разных экранов */
  @media (max-width: 768px) {
    .marquee-item {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .marquee-item {
      font-size: 12px;
    }
  }