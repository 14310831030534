.login-page-body {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование по горизонтали */
    padding: 30px;
    overflow-y: auto; /* Добавляем скролл, если контент превышает высоту экрана */
}

.login-page-title {
    opacity: 0.71; /* Прозрачность текста */
    color: #4F2D6B; /* Цвет текста */
    font-size: 40px;
    text-align: center; /* Центрирование текста */
    margin-top: 10vh; /* Отступ от верхнего края */
}

.login-page-input-group {
    margin-top: 20px; /* Отступ между элементами */
    width: 100%;
    max-width: 400px; /* Максимальная ширина формы */
}

.login-page-label {
    display: block;
    margin-bottom: 5px; /* Отступ между меткой и полем ввода */
    color: #4F2D6B; /* Цвет текста метки */
}

.login-page-input {
    width: 100%; /* Полная ширина поля ввода */
    padding: 10px; /* Внутренние отступы */
    border-radius: 5px; /* Закругленные углы */
    border: 1px solid #ccc; /* Цвет границы */
}

.login-page-cta-button {
    background-color: #de4a36;
    background-image: linear-gradient(to right, #de4a36, #f06a56);
    background-size: 200% 200%;
    background-position: 0% 50%;
    border: none;
    color: white; /* Цвет текста */
    padding: 15px 30px; /* Увеличиваем внутренние отступы для улучшения кликабельности */
    text-align: center;
    text-decoration: none; /* Убираем подчеркивание */
    display: inline-block;
    font-size: 1.2rem; /* Увеличиваем размер шрифта для лучшей читабельности */
    border-radius: 10px;
    cursor: pointer;
    transition: background-position 0.5s ease, background-color 0.5s ease, box-shadow 0.5s ease;
    
    margin-top: 20px; /* Отступ между элементами */
}

.login-page-cta-button:hover {
    background-position: 100% 50%;
}

/* Адаптивные стили для экранов с шириной до 768px */
@media (max-width: 768px) {
   .login-page-title {
       font-size: 30px; 
       margin-top: 5vh;
   }
   
   .login-page-cta-button {
       font-size :1rem ; 
       padding :10px ; 
   }
}

/* Адаптивные стили для экранов с шириной до 480px */
@media (max-width: 480px) {
   .login-page-title {
       font-size :24px ;
   }
}

/* Адаптивные стили для экранов с шириной до 320px */
@media (max-width: 320px) {
   .login-page-title {
       font-size :20px ;
   }
}
