body, html {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  height: 100%;
}

.faq-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #333;
}

.faq-container {
  width: 70%;
  padding: 8vh;
  background-color: rgba(236, 236, 236, 0.4);
  border-radius: 20px;
  margin-left: 10px; 
}

.faq-item {
  margin-bottom: 10px; /* Отступ между вопросами */
}

.faq-question {
  background-color: #fff; /* Белый фон для вопросов */
  border: none;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  cursor: pointer; /* Указатель при наведении */
  position: relative; /* Для позиционирования стрелки */
}

.faq-question:hover {
  background-color: #f0f0f0; /* Изменение фона при наведении */
}

.arrow {
  position: absolute;
  right: 15px; /* Отступ от правого края */
  width: 24px; /* Ширина стрелки */
  height: 24px; /* Высота стрелки */
  transition: transform 0.3s ease; /* Плавный переход для стрелки */
}

.arrow.open {
 transform: rotate(180deg); /* Поворот стрелки при открытом состоянии */
}

.faq-answer {
  font-size: 1rem; 
  padding-left: 20px; /* Отступ для визуального отделения ответа от вопроса */
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
  .faq-container {
      width: 100%; 
      margin-left: 0; 
      padding: 5vh; 
  }
}
