body, html {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    height: 100%;
    overflow-x: hidden;
}

.tax-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    flex: 1 1;
    color: #333;
}

.tax-container .main-content
{
  display: block;
}

.tax-container {
    width: 70%; /* Установите ширину контейнера в 70% */
    padding: 8vh;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    margin: 0 0 0 10px; /* Установите отступ слева */
    max-width: 100%; /* Добавляем максимальную ширину для безопасности */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; /* Изменено с center на flex-start для выравнивания по левому краю */
    box-sizing: border-box;
    overflow-y: auto; /* Добавьте бесконечную прокрутку */
    overflow-x: hidden; /* Уберите горизонтальный скролл */
}

.tax {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Изменено с center на flex-start */
}

.tax-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    align-self: flex-start; /* Добавлено для выравнивания по левому краю */
    color: #333;
  }

.tax .tax-content {
    font-size: 1rem;
    margin-bottom: 5vh;
}

/* Стили для статьи */
.tax .tax {
    margin-bottom: 20px;
    background-color: rgba(236, 236, 236, 0.4);
    border-radius: 20px;
    padding: 10px;
    width: 100%; /* Ширина статьи внутри контейнера */
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
    .tax-container {
        width: 100%; /* Установите ширину контейнера на 100% для мобильных устройств */
        margin: 0; /* Уберите отступ слева для мобильных устройств */
        padding: 5vh; /* Уменьшите padding для мобильных устройств */
    }
    .tax {
        padding: 5px; /* Уменьшите padding для мобильных устройств */
        align-items: flex-start; /* Убедитесь, что выравнивание остается по левому краю на мобильных устройствах */
    }
    .tax .tax {
        padding: 5px; /* Уменьшите padding для статей на мобильных устройствах */
        align-items: flex-start; /* Убедитесь, что выравнивание остается по левому краю на мобильных устройствах */
    }
}